import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook

function WatchPage() {
  const { id } = useParams(); // Destructure the id from the params
  const [movie, setMovie] = useState(null);

  useEffect(() => {
    // Fetch the movie details using the id from the route parameters
    fetchMovieDetails();
  }, [id]);

  const fetchMovieDetails = async () => {
    try {
      const response = await fetch(`http://localhost:5000/api/movie/${id}`);
      const data = await response.json();
      setMovie(data);
    } catch (error) {
      console.error('Error fetching movie details:', error);
    }
  };

  if (!movie) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{movie.title}</h1>
      <p>{movie.description}</p>
      <video src={movie.videoUrl} controls />
    </div>
  );
}

export default WatchPage;
