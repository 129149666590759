import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './App.css';

// Accessibility requirement for react-modal
Modal.setAppElement('#root');

const LoadingModal = ({ isOpen, closeModal, countdown }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dark overlay background
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          width: '100vw', // Full viewport width
          height: '100vh', // Full viewport height
          position: 'fixed',
          top: 0,
          left: 0,
        },
        content: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#222', // Dark background for modal
          color: '#fff', // White text
          padding: '20px',
          border: 'none', // No border
          width: '100vw', // Full width
          height: '100vh', // Full height
          boxSizing: 'border-box', // Include padding in height/width
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto', // Allow scrolling if content exceeds screen height
        },
      }}
    >
      <button
        onClick={closeModal}
        style={{
          background: 'transparent',
          color: '#fff',
          border: 'none',
          fontSize: '1.5em',
          cursor: 'pointer',
          position: 'absolute',
          top: '10px',
          right: '15px',
          fontWeight: 'bold',
        }}
      >
        X
      </button>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ fontSize: '1.5em', marginBottom: '10px', color: '#ff7f50' }}>
          Loading... Please wait for {countdown} seconds.
        </h2>
        <p style={{ fontSize: '1.1em', marginBottom: '10px' }}>Steps to watch a movie:</p>
        <ol style={{ textAlign: 'left', margin: '0', paddingLeft: '20px' }}>
          <li style={{ marginBottom: '8px', color: '#ff7f50' }}>Search or select a movie from the list.</li>
          <li style={{ marginBottom: '8px', color: '#ff7f50' }}>Click on "Watch Now".</li>
          <li style={{ marginBottom: '8px', color: '#ff7f50' }}>Enjoy the movie!</li>
        </ol>
      </div>
    </Modal>
  );
};



const MovieModal = ({ isModalOpen, closeModal, videoUrl }) => {
  const [iframeContent, setIframeContent] = useState('');

  useEffect(() => {
    const fetchIframeContent = async () => {
      if (isModalOpen && videoUrl) {
        try {
          const response = await axios.get('https://moviepy.webys.org/api/get_video_frame', {
            params: { video_url: videoUrl }
          });
          setIframeContent(response.data);
          
          // Dynamically re-run scripts after the content is injected
          setTimeout(() => {
            const script = document.createElement('script');
            script.innerHTML = 'window.playVideo = function() { const iframe = document.getElementById("video-iframe"); const src = iframe.src; if (src.includes("youtube.com")) { iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "playVideo", args: "" }), "*"); } else { alert("Play function not supported."); } };';
            document.body.appendChild(script);
          }, 0);
        } catch (error) {
          console.error('Error fetching iframe content:', error);
        }
      }
    };
    fetchIframeContent();
  }, [isModalOpen, videoUrl]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Movie Player"
      className="modal"
      overlayClassName="overlay"
    >
      <button className="close-button" onClick={closeModal}>X</button>
      <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: iframeContent }}
      />
    </Modal>
  );
};

function App() {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [featuredMovies, setFeaturedMovies] = useState([]);
  const [popularMovies, setPopularMovies] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(4);

  // Fetch featured and popular movies on component mount
  useEffect(() => {
    fetchFeaturedMovies();
    fetchPopularMovies();
    // Show loading popup on page load
    handleOpenLoadingModal();
  }, []);

  // Fetch featured movies
  const fetchFeaturedMovies = async () => {
    try {
      const response = await axios.get('https://movie2.webys.org/api/featured');
      setFeaturedMovies(response.data.movies);
    } catch (error) {
      console.error('Error fetching featured movies:', error);
    }
  };

  // Fetch popular movies
  const fetchPopularMovies = async () => {
    try {
      const response = await axios.get('https://movie2.webys.org/api/popular');
      setPopularMovies(response.data.movies);
    } catch (error) {
      console.error('Error fetching popular movies:', error);
    }
  };

  // Handle movie search
  const handleSearch = async () => {
    if (!query) return;
    handleOpenLoadingModal();  // Show the loading popup before search
    try {
      const response = await axios.post('https://movie2.webys.org/api/search', { query });
      setSearchResults(response.data.links);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Handle clicking on "Watch Now"
  const handleWatchNow = (url, event) => {
    event.preventDefault();
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  // Close the movie modal
  const closeModal = async () => {
    setIsModalOpen(false);
    setVideoUrl('');
  };

  // Handle the loading popup logic
  const handleOpenLoadingModal = () => {
    setIsLoadingModalOpen(true);
    let timeLeft = 4;
    const interval = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(interval);
        setIsLoadingModalOpen(false);
      }
    }, 1000);
  };

  return (
    <div className="App">
      <h1>[Y]Movies</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {/* Display search results */}
      <div className="results-container">
        {searchResults.map((result, index) => (
          <div key={index} className="result-item">
            <img src={result.img} alt={result.title} />
            <a
              href={result.href}
              className="watch-now"
              onClick={(event) => handleWatchNow(result.href, event)}
            >
              {result.title}
            </a>
            <p>IMDB: {result.imdb}</p>
            <p>Year: {result.year}</p>
          </div>
        ))}
      </div>

      {/* Display featured movies */}
      <h1>Featured Movies</h1>
      <div className="movie-container">
        {featuredMovies.map((movie, index) => (
          <div key={index} className="movie-card">
            <img className="movie-poster" src={movie.poster_url} alt={movie.title} />
            <div className="movie-details">
              <h3>{movie.title}</h3>
              <p>{movie.year}</p>
              <p>Rating: {movie.rating}</p>
              <button onClick={(event) => handleWatchNow(movie.movie_url, event)}>
                Watch Now
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Display popular movies */}
      <h1>Popular Movies</h1>
      <div className="movie-container">
        {popularMovies.map((movie, index) => (
          <div key={index} className="movie-card">
            <img className="movie-poster" src={movie.poster_url} alt={movie.title} />
            <div className="movie-details">
              <h3>{movie.title}</h3>
              <p>{movie.year}</p>
              <p>Rating: {movie.rating}</p>
              <button onClick={(event) => handleWatchNow(movie.movie_url, event)}>
                Watch Now
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Movie Modal */}
      <MovieModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        videoUrl={videoUrl}
      />

      {/* Loading Modal */}
      <LoadingModal
        isOpen={isLoadingModalOpen}
        closeModal={() => setIsLoadingModalOpen(false)}
        countdown={countdown}
      />
    </div>
  );
}

export default App;
